// import React, { useEffect, useState } from "react";
// import Sidebar from "../Chat/Sidebar";
// import { Button, Modal, Form, Dropdown, Table, Pagination } from "react-bootstrap";
// import { IoIosEye } from "react-icons/io";
// import { MdDeleteForever } from "react-icons/md";
// import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
// import { toast, ToastContainer } from 'react-toastify';

// const ManageGroups = () => {
//     const [showGroupModal, setShowGroupModal] = useState(false);
//     const [showViewModal, setShowViewModal] = useState(false);
//     const [newGroupName, setNewGroupName] = useState('');
//     const [groups, setGroups] = useState([]);
//     const [groupId, setGroupId] = useState('');
//     const [selectedUserId, setSelectedUserId] = useState('');
//     const [adminUsers, setAdminUsers] = useState([]);
//     const [selectedGroup, setSelectedGroup] = useState(null);

//     useEffect(() => {
//         const fetchGroups = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/groups');
//                 const data = await response.json();
//                 setGroups(data.groups || []);
//             } catch (error) {
//                 console.error('Error fetching groups:', error);
//             }
//         };

//         fetchGroups();
//     }, []);

//     useEffect(() => {
//         const fetchAdminUsers = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/departuser');
//                 const data = await response.json();
//                 setAdminUsers(data);
//             } catch (error) {
//                 console.error('Error fetching admin users:', error);
//             }
//         };

//         fetchAdminUsers();
//     }, []);

//     const handleCreateGroup = async () => {
//         if (newGroupName.trim() !== '' && selectedUserId) {
//             const response = await fetch('https://demochatapi.emedha.in/api/groups', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ groupName: newGroupName, groupId, userId: selectedUserId }),
//             });

//             if (response.ok) {
//                 const newGroup = await response.json();
//                 setGroups([...groups, newGroup]);
//                 resetForm();
//                 setShowGroupModal(false);
//                 toast.success("Group created successfully!");
//             } else {
//                 console.error('Failed to create group:', await response.json());
//             }
//         } else {
//             toast.error('Group name and User ID are required.');
//         }
//     };

//     const resetForm = () => {
//         setNewGroupName('');
//         setGroupId('');
//         setSelectedUserId('');
//     };

//     const handleView = (id) => {
//         const group = groups.find(group => group.id === id);
//         setSelectedGroup(group);
//         setShowViewModal(true);
//     };

//     const handleDelete = async (id) => {
//         const confirmDelete = window.confirm("Are you sure you want to delete this group?");
//         if (confirmDelete) {
//             try {
//                 const response = await fetch(`https://demochatapi.emedha.in/api/groups/${id}`, {
//                     method: 'DELETE',
//                 });

//                 if (response.ok) {
//                     setGroups(groups.filter(group => group.id !== id));
//                     toast.success("Group deleted successfully!");
//                 } else {
//                     console.error('Failed to delete group:', await response.json());
//                 }
//             } catch (error) {
//                 console.error('Error deleting group:', error);
//             }
//         }
//     };
//     const [currentPage, setCurrentPage] = useState(0);
//     const itemsPerPage = 5; // Set the number of items per page
//     const pageCount = Math.ceil(groups.length / itemsPerPage);
//     const displayedGroups = groups.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);



//     return (
//         <>
//             <Sidebar />
//             <div id='main'>
//                 <div className="container">
//                     <div className="row justify-content-center mb-3">
//                         <h3>List of Groups</h3>
//                     </div>
//                     <div className="row justify-content-center mb-3">
//                         <div className="col-lg-12 text-start">
//                             <Button variant="primary" onClick={() => setShowGroupModal(true)}>
//                                 + Add Group
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//                 <Table striped bordered hover>
//                     <thead>
//                         <tr>
//                             <th>ID</th>
//                             <th>Group Name</th>
//                             <th>Group Id</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {displayedGroups.map((group, index) => (
//                             <tr key={group.id}>
//                                  <td>{index + 1 + currentPage * itemsPerPage}</td>
//                                 <td>{group.groupName}</td>
//                                 <td>{group.groupId}</td>
//                                 <td>
//                                     <Dropdown>
//                                         <Dropdown.Toggle variant="success" id="dropdown-basic">
//                                             <PiDotsThreeOutlineVerticalBold />
//                                         </Dropdown.Toggle>
//                                         <Dropdown.Menu>
//                                             <Dropdown.Item onClick={() => handleView(group.id)}>
//                                                 <IoIosEye /> View
//                                             </Dropdown.Item>
//                                             <Dropdown.Item onClick={() => handleDelete(group.id)}>
//                                                 <MdDeleteForever /> Delete
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </Table>
//                 <div className="d-flex justify-content-end mt-3 p-3">
//                     <Pagination>
//                         <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
//                         <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
//                         {[...Array(pageCount)].map((_, i) => (
//                             <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
//                                 {i + 1}
//                             </Pagination.Item>
//                         ))}
//                         <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
//                         <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
//                     </Pagination>
//                 </div>
//                 {/* Group View Modal */}
//                 <Modal show={showViewModal} onHide={() => { setShowViewModal(false); setSelectedGroup(null); }}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Group Details</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         {selectedGroup ? (
//                             <div>
//                                  <p><strong>Admin UserId:</strong> {selectedGroup.userId}</p>
//                                  <p><strong>Group ID:</strong> {selectedGroup.groupId}</p>

//                                 <p><strong>Group Name: </strong>{selectedGroup.groupName}</p>




//                             </div>
//                         ) : (
//                             <p>Loading...</p>
//                         )}
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => { setShowViewModal(false); setSelectedGroup(null); }}>
//                             Close
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>

//                 {/* Group Creation Modal */}
//                 <Modal show={showGroupModal} onHide={() => { setShowGroupModal(false); resetForm(); }}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Create New Group</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form>
//                             <Form.Group controlId="groupId">
//                                 <Form.Label>Group Id</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     placeholder="Enter Group Id"
//                                     value={groupId}
//                                     onChange={(e) => setGroupId(e.target.value)}
//                                 />
//                             </Form.Group>
//                             <Form.Group controlId="newGroupName">
//                                 <Form.Label>Group Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     placeholder="Enter group name"
//                                     value={newGroupName}
//                                     onChange={(e) => setNewGroupName(e.target.value)}
//                                 />
//                             </Form.Group>
//                             <Form.Group controlId="userId">
//                                 <Form.Label>User Id</Form.Label>
//                                 <Form.Control
//                                     as="select"
//                                     value={selectedUserId}
//                                     onChange={(e) => setSelectedUserId(e.target.value)}
//                                 >
//                                     <option value="">Select User ID</option>
//                                     {adminUsers.map((user) => (
//                                         <option key={user.id} value={user.id}>
//                                             {user.username}
//                                         </option>
//                                     ))}
//                                 </Form.Control>
//                             </Form.Group>
//                         </Form>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="primary" onClick={handleCreateGroup}>
//                             Create Group
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>

//                 <ToastContainer position="top-right" autoClose={6000} />
//             </div>
//         </>
//     );
// };

// export default ManageGroups;

import React, { useEffect, useState } from "react";
import Sidebar from "../Chat/Sidebar";
import { Button, Modal, Form, Dropdown, Table, Pagination, Row, Col } from "react-bootstrap";
import { IoIosEye, IoIosNotifications, IoIosSearch } from "react-icons/io";
import { MdAccountCircle, MdDeleteForever } from "react-icons/md";
import { PiDotsThreeOutlineVerticalBold } from "react-icons/pi";
import { toast, ToastContainer } from 'react-toastify';
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';



const ManageGroups = () => {
    const navigate = useNavigate();
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupId, setGroupId] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [adminUsers, setAdminUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);

    // Search state
    const [searchQuery, setSearchQuery] = useState('');

    // Pagination states


    // useEffect(() => {
    //     const fetchGroups = async () => {
    //         try {
    //             const response = await fetch('https://demochatapi.emedha.in/api/groups');
    //             const data = await response.json();
    //             setGroups(data.groups || []);
    //         } catch (error) {
    //             console.error('Error fetching groups:', error);
    //         }
    //     };

    //     fetchGroups();
    // }, []);
    // console.log(groups)





    const [groupsData, setGroupss] = useState([]);

    //this useEffect is for all data of admins shows in superadmin


    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/userGroups', {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                        },
                    });
                    const data = await response.json();
                    setGroupss(data.results || []);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchGroups();
    }, []); // Runs once on component mount

    console.log(groupsData);





    //this useEffect is for all admins that shows there data 

    useEffect(() => {
        const fetchGroups = async () => {
            const userIdss = localStorage.getItem('userId')
            const userIdInt = parseInt(userIdss, 10);

            if (userIdInt) {
                try {
                    const response = await fetch(`https://demochatapi.emedha.in/api/api1/groups?userId=${userIdInt}`, {
                        method: 'GET',

                    });
                    const data = await response.json();
                    setGroupss(data.groups || []);
                } catch (error) {
                    console.error('Error fetching groups:', error);
                }
            }
        };

        fetchGroups();
    }, []);


    console.log(groupsData)

    // useEffect(() => {
    //     const fetchCombinedData = async () => {
    //         const userId = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).id : null;
    //         if (userId) {
    //             try {
    //                 const response = await fetch('https://demochatapi.emedha.in/api/combaindata', {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                     },
    //                     body: JSON.stringify({ userId }), // Ensure you're stringifying the object
    //                 });
    //                 const data = await response.json();
    //                 setGroups(data);
    //             } catch (error) {
    //                 console.error('Error fetching combined data:', error);
    //             }
    //         }

    //     };

    //     fetchCombinedData();
    // }, []);
    // console.log(groups)


    useEffect(() => {
        const fetchAdminUsers = async () => {
            try {
                const response = await fetch('https://demochatapi.emedha.in/api/departuser');
                const data = await response.json();
                setAdminUsers(data);
            } catch (error) {
                console.error('Error fetching admin users:', error);
            }
        };

        fetchAdminUsers();
    }, []);

    // const handleCreateGroup = async () => {
    //     const userIdss = localStorage.getItem('userId')
    //     const userIdInt = parseInt(userIdss, 10);
    //     if (newGroupName.trim() !== '' && selectedUserId) {
    //         const response = await fetch('https://demochatapi.emedha.in/api/groups', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ groupName: newGroupName, groupId, userId: userIdInt }),
    //         });

    //         if (response.ok) {
    //             const newGroup = await response.json();
    //             // setGroups([...groups, newGroup]);
    //             setGroupss(prevGroups => [...prevGroups, newGroup]);
    //             resetForm();
    //             setShowGroupModal(false);
    //             toast.success("Group created successfully!");
    //             window.location.reload();
    //         } else {
    //             console.error('Failed to create group:', await response.json());
    //         }
    //     } else {
    //         toast.error('Group name and User ID are required.');
    //     }
    // };



    const handleCreateGroup = async () => {
        const userIdss = localStorage.getItem('userId');
        const userIdInt = parseInt(userIdss, 10);

        if (newGroupName.trim() === '' || !selectedUserId) {
            Swal.fire({
                icon: 'warning',
                title: 'Validation Error',
                text: 'Group name and User ID are required.',
            });
            return;
        }

        try {
            const response = await fetch('https://demochatapi.emedha.in/api/groups', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ groupName: newGroupName, groupId, userId: userIdInt }),
            });

            if (response.ok) {
                const newGroup = await response.json();
                setGroupss(prevGroups => [...prevGroups, newGroup]);

                setShowGroupModal(false);
                resetForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Group created successfully!',
                }).then(() => {
                    window.location.reload(); // Reload after closing the alert
                });
            } else {
                const errorData = await response.json();
                console.error('Failed to create group:', errorData);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorData.message || 'Failed to create group. Please try again.',
                });
            }
        } catch (error) {
            console.error('Error creating group:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An unexpected error occurred. Please try again.',
            });
        }
    };

    const resetForm = () => {
        setNewGroupName('');
        setGroupId('');
        setSelectedUserId('');
    };

    const handleView = (id) => {
        const group = groupsData.find(group => group.id === id);
        setSelectedGroup(group);
        setShowViewModal(true);
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this group?");
        if (confirmDelete) {
            try {
                const response = await fetch(`https://demochatapi.emedha.in/api/groups/${id}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    setGroupss(groupsData.filter(group => group.id !== id));
                    toast.success("Group deleted successfully!");
                } else {
                    console.error('Failed to delete group:', await response.json());
                }
            } catch (error) {
                console.error('Error deleting group:', error);
            }
        }
    };
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;




    const userIdss = localStorage.getItem('userId')
    const userIdInt = parseInt(userIdss, 10);

    // const pageCount = Math.ceil(groupsData.length / itemsPerPage);
    // const displayedGroups = groupsData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    const [searchType, setSearchType] = useState(); // Default search type


    // const filteredGroups = groupsData.filter(group => {
    //     const query = searchQuery.toLowerCase();
    //     const matchesAdminName = group.username.toLowerCase().includes(query);
    //     const matchesGroupName = group.groupName.toLowerCase().includes(query);
    //     const matchesGroupId = group.groupId.toString().includes(searchQuery); // No need to lower case since it's a number

    //     return matchesAdminName || matchesGroupName || matchesGroupId;
    // });

    const filteredGroups = groupsData.filter(group => {
        console.log(group); // Log the group object
        const query = searchQuery.toLowerCase();
        const matchesAdminName = group.username && group.username.toLowerCase().includes(query);
        const matchesGroupName = group.groupName && group.groupName.toLowerCase().includes(query);
        const matchesGroupId = group.groupId && group.groupId.toString().includes(searchQuery);

        return matchesAdminName || matchesGroupName || matchesGroupId;
    });


    const pageCount = Math.ceil(filteredGroups.length / itemsPerPage);
    const displayedGroups = filteredGroups.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);



    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleItemClick = (action) => {
        console.log(action);
        setDropdownVisible(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('loginEmail');
        localStorage.removeItem('rememberMe');
        sessionStorage.removeItem('username');
        localStorage.removeItem('userId');
        navigate('/');
    };



    return (
        <>
            <Sidebar />
            <div id='main'>

                <div className="container-fluid bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong>Groups</strong></h3>
                        </div>
                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <div style={{ position: 'relative' }}>
                                <MdAccountCircle
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisible && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div>
                                        <div onClick={handleLogout} className="dropdown-item">
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <Row>
                    <Col>
                <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search "
                        className="form-control"
                        style={{
                            paddingLeft: '40px', // Space for the icon
                            paddingRight: '40px', // Space for any right-side elements
                            borderRadius: '25px', // Rounded corners
                            border: '1px solid #ced4da', // Border color

                            transition: 'border-color 0.3s',
                        }}
                        onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
                        onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
                    />
                    <IoIosSearch

                        style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
                        size={20} // Adjust size as needed
                    />
                </div>
                </Col>
                <Col>
           
                    <div className='d-flex flex-row justify-content-end m-2 '>
                        <Button style={{ backgroundColor: 'rgb(125, 133, 195)' }} onClick={() => setShowGroupModal(true)}>
                            + Add Group
                        </Button>
                    </div>

              
                </Col> 
                </Row>
                <br></br>

                <Table striped bordered hover >
                    <thead >
                        <tr>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} >ID</th>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} >Admin Name</th>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} >Group Name</th>
                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }} >Group Id</th>

                            <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {usernames !== 'admin' && loginEmail === 'satyavarma0611@gmail.com' && (
                    )} */}
                        {displayedGroups.map((group, index) => (
                            <tr key={group.id}>
                                <td>{index + 1 + currentPage * itemsPerPage}</td>
                                <td>{group.username}</td>
                                <td>{group.groupName}</td>
                                <td>{group.groupId}</td>

                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle style={{ backgroundColor: 'rgb(125, 133, 195)' }} id="dropdown-basic">
                                            <PiDotsThreeOutlineVerticalBold />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleView(group.id)}>
                                                <IoIosEye /> View
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item onClick={() => handleDelete(group.id)}>
                                                <MdDeleteForever /> Delete
                                            </Dropdown.Item> */}

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Pagination Component */}
                <div className='d-flex flex-row justify-content-end m-2 '>
                    <Pagination>
                        <Pagination.First onClick={() => setCurrentPage(0)} disabled={currentPage === 0} />
                        <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0} />
                        {[...Array(pageCount)].map((_, i) => (
                            <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount - 1))} disabled={currentPage === pageCount - 1} />
                        <Pagination.Last onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage === pageCount - 1} />
                    </Pagination>
                </div>

                {/* Group View Modal */}
                <Modal show={showViewModal} onHide={() => { setShowViewModal(false); setSelectedGroup(null); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Group Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedGroup ? (
                            <div>
                                <p><strong>Admin UserId:</strong> {selectedGroup.username}</p>
                                <p><strong>Group ID:</strong> {selectedGroup.groupId}</p>
                                <p><strong>Group Name: </strong>{selectedGroup.groupName}</p>
                            </div>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowViewModal(false); setSelectedGroup(null); }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Group Creation Modal */}
                <Modal show={showGroupModal} onHide={() => { setShowGroupModal(false); resetForm(); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="groupId">
                                <Form.Label>Group Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Group Id"
                                    value={groupId}
                                    onChange={(e) => setGroupId(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="newGroupName">
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter group name"
                                    value={newGroupName}
                                    onChange={(e) => setNewGroupName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="userId">
                                <Form.Label>User Id</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedUserId}
                                    onChange={(e) => setSelectedUserId(e.target.value)}
                                >
                                    <option value="">Select User ID</option>
                                    
        {localStorage.getItem('token') ? (
                                  adminUsers.map((userData) => (
                                        <option key={userData.id} value={userData.id}>
                                            {userData.username}
                                        </option>
                                    ))
                                ) : (    
                                    adminUsers.filter((user => user.id === userIdInt)).map((user) => (
                                        <option key={user.id} value={user.username}>
                                            {user.username}
                                        </option>
                                    ))

                                )}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ backgroundColor: 'rgb(125, 133, 195)' }} onClick={handleCreateGroup}>
                            Create Group
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* <ToastContainer position="top-right" autoClose={6000} /> */}
            </div>
        </>
    );
};

export default ManageGroups;
