// import React, { useEffect, useState } from 'react';
// import Sidebar from '../Chat/Sidebar';
// import { IoIosSearch, IoMdContact } from 'react-icons/io';
// import { BiSolidSend } from "react-icons/bi";
// import { IoSend } from "react-icons/io5";
// import bg from './bg.jpeg';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { Col, Dropdown, Row } from 'react-bootstrap';
// import { MdArchive, MdOutlineSendAndArchive } from 'react-icons/md';

// const SendUserMessage = () => {
//     const [contacts, setContacts] = useState([]);
//     const [selectedContact, setSelectedContact] = useState(null);
//     const [messages, setMessages] = useState([]);
//     const [newMessage, setNewMessage] = useState('');
//     const [searchTerm, setSearchTerm] = useState('');
//     const [response, setResponse] = useState('');
//     const [error, setError] = useState('');
//     const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
//     const [archivedContacts, setArchivedContacts] = useState([]);
//     // Close dropdown when clicking outside
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (!event.target.closest('.dropdown-toggle')) {
//                 setActiveDropdown(null); // Close dropdown if click is outside
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside); // Add listener for clicks outside
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside); // Clean up listener on unmount
//         };
//     }, []);

//     // Fetch contacts based on token
//     useEffect(() => {
//         const fetchContactsWithToken = async () => {
//             try {
//                 const token = localStorage.getItem('token');
//                 if (token) {
//                     const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
//                         headers: {
//                             'Authorization': `Bearer ${token}`,
//                         },
//                     });
//                     const results = await response.json();
//                     setContacts(results);
//                 }
//             } catch (error) {
//                 console.error('Error fetching contacts:', error);
//             }
//         };

//         fetchContactsWithToken();
//     }, []);

//     // Fetch contacts based on userId
    // useEffect(() => {
    //     const fetchContactsWithUserId = async () => {
    //         const userId = localStorage.getItem('userId');
    //         const userIdInt = parseInt(userId, 10);

    //         if (userIdInt) {
    //             try {
    //                 const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
    //                     method: 'GET',
    //                 });
    //                 const data = await response.json();
    //                 setContacts(data.contacts || []);
    //             } catch (error) {
    //                 console.error('Error fetching groups:', error);
    //             }
    //         }
    //     };

    //     fetchContactsWithUserId();
    // }, []);

//     const handleContactClick = (contact) => {
//         setSelectedContact(contact);
//         setMessages([]); // Reset messages for the selected contact
//     };

//     const handleSendMessage = async () => {
//         if (newMessage.trim() === '' || !selectedContact) return;

//         const messageToSend = newMessage;

//         // Create a new message object to display in the chat
//         const newMessageObject = {
//             id: messages.length + 1,
//             text: messageToSend,
//             sender: 'me',
//             timestamp: new Date().toLocaleTimeString(),
//         };

//         // Update the messages state
//         setMessages((prevMessages) => [...prevMessages, newMessageObject]);

//         try {
//             const mobile = selectedContact.mobile; // Get the selected contact's mobile number
//             const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=8919171985&message=${encodeURIComponent(messageToSend)}`, {
//                 method: 'POST', // Using POST as per the API specification
//             });

//             if (!res.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const contentType = res.headers.get("content-type");
//             let data;

//             if (contentType && contentType.includes("application/json")) {
//                 data = await res.json();
//             } else {
//                 data = await res.text();
//             }

//             setResponse(data); // Handle the response from the API
//         } catch (err) {
//             setError(err.message);
//         } finally {
//             // Clear the input field
//             setNewMessage('');
//         }
//     };

//     const filteredContacts = contacts.filter(contact =>
//         contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const filteredArchivedContacts = archivedContacts.filter(contact =>
//         contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
//     );


//     const toggleDropdown = (contactId) => {
//         if (activeDropdown === contactId) {
//             setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
//         } else {
//             setActiveDropdown(contactId); // Show the dropdown for the clicked contact
//         }
//     };


//     // const handleArchiveContact = (contactId) => {
//     //     const contactToArchive = contacts.find((contact) => contact.id === contactId);
        
//     //     // Remove the contact from the main contacts
//     //     setContacts((prevContacts) => prevContacts.filter((contact) => contact.id !== contactId));
        
//     //     // Add the contact to the archived contacts
//     //     setArchivedContacts((prevArchived) => [...prevArchived, contactToArchive]);
        
//     //     setActiveDropdown(null); // Close dropdown
//     // };

//     // // Unarchive the contact
//     // const handleUnarchiveContact = (contactId) => {
//     //     const contactToUnarchive = archivedContacts.find((contact) => contact.id === contactId);

//     //     // Remove the contact from archived contacts
//     //     setArchivedContacts((prevArchived) => prevArchived.filter((contact) => contact.id !== contactId));

//     //     // Add the contact back to main contacts
//     //     setContacts((prevContacts) => [...prevContacts, contactToUnarchive]);
//     // };

//     return (
//         <>
//             <Sidebar />
//             <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
//                 <div style={{ backgroundColor: '#E6E8F4', width: '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
//                     <Row> <Col> <h3 style={{ margin: '0 0 10px' }}>Contacts</h3> </Col> <Col className='text-end'> <BsThreeDotsVertical style={{ fontSize: '20px' }} /> </Col></Row>

//                     <div style={{ marginBottom: '10px', position: 'relative', width: '230px', display: 'flex', alignItems: 'center' }}>
//                         <input
//                             type="text"
//                             value={searchTerm}
//                             onChange={(e) => setSearchTerm(e.target.value)}
//                             placeholder="Search "
//                             className="form-control"
//                             style={{
//                                 paddingLeft: '40px', // Space for the icon
//                                 paddingRight: '40px', // Space for any right-side elements
//                                 borderRadius: '25px', // Rounded corners
//                                 border: '1px solid rgb(125, 133, 195)', // Border color

//                                 transition: 'border-color 0.3s',
//                             }}
//                             onFocus={(e) => e.target.style.borderColor = ' rgb(125, 133, 195)'} // Change border color on focus
//                             onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'} // Reset border color on blur
//                         />
//                         <IoIosSearch

//                             style={{ position: 'absolute', left: '10px', color: '#ced4da' }}
//                             size={20} // Adjust size as needed
//                         />
//                     </div>
//                     {/* <h4 className='text-center'> <MdArchive />  Archived</h4>  */}

//                     {filteredContacts.map(contact => (
//                         <div
//                             key={contact.id}
//                             onClick={() => handleContactClick(contact)}
//                             style={{
//                                 cursor: 'pointer',
//                                 padding: '10px',
//                                 margin: '5px',
//                                 borderBottom: '1px solid #eee',
//                                 // borderRadius:'20px'
//                                 // transition: 'background-color 0.1s',
//                             }}
//                             onMouseEnter={(e) => {
//                                 e.currentTarget.style.backgroundColor = '#7D86C3';
//                                 e.currentTarget.style.color = 'white';
//                             }}
//                             onMouseLeave={(e) => {
//                                 e.currentTarget.style.backgroundColor = '#E6E8F4';
//                                 e.currentTarget.style.color = 'black';
//                             }}
//                         >
//                                <Row>
//                                 <Col>{contact.mobile}</Col>
//                                 <Col className='text-end'>
//                                     <MdOutlineSendAndArchive
//                                         id="dropdown-custom-components"
//                                         style={{ fontSize: '20px', cursor: 'pointer' }}
//                                         onClick={(e) => {
//                                             e.stopPropagation(); // Prevent dropdown from closing
//                                             toggleDropdown(contact.id); // Toggle dropdown
//                                         }}
//                                     />
//                                     {/* <Dropdown show={activeDropdown === contact.id}>
//                                         <Dropdown.Menu>
//                                            <Dropdown.Item onClick={() => handleArchiveContact(contact.id)}> 
                                          
//                                                 Archive
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown> */}
//                                 </Col>
//                             </Row>
//                         </div>
//                     ))}
//                 </div>
//                 <div style={{ flex: 1,  display: 'flex', flexDirection: 'column' }}>
//                     {selectedContact ? (
//                         <>
//                             <div style={{ borderBottom: '1px solid #ccc' }}>
//                                 <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}><IoMdContact style={{fontSize:'30px',color:'rgb(125, 133, 195)', margin:'5px'}}/>{selectedContact.mobile}</h3>
//                             </div>
//                             <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', border: '1px solid #ccc', backgroundColor: '#E6E8F4',    backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,    borderTop: 'none' }}>
//                                 {messages.map(msg => (
//                                     <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'me' ? 'right' : 'left' }}>
//                                         <div style={{
//                                             display: 'inline-block',
//                                             padding: '8px',
//                                             borderRadius: '10px',
//                                             backgroundColor: msg.sender === 'me' ? '#dcf8c6' : '#ffffff',
//                                             maxWidth: '70%',
//                                             boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
//                                         }}>
//                                             <strong>{msg.sender === 'me' ? 'You' : selectedContact.mobile}:</strong>
//                                             <span> {msg.text} </span>
//                                             <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.timestamp}</small>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
//                                 {/* <input
//                                     type="text"
//                                     value={newMessage}
//                                     onChange={(e) => setNewMessage(e.target.value)}
//                                     placeholder="Type a message..."
//                                     style={{ flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
//                                 /> */}


//                                 <input
//                                     type="text"
//                                     value={newMessage}
//                                     onChange={(e) => setNewMessage(e.target.value)}
//                                     placeholder="Type a message...."

//                                     style={{
//                                         flex: 1, padding: '10px',
//                                         paddingLeft: '40px', // Space for the icon
//                                         paddingRight: '40px', // Space for any right-side elements
//                                         borderRadius: '25px', // Rounded corners
//                                         border: '1px solid black', // Border color


//                                     }}
//                                     onFocus={(e) => e.target.style.borderColor = 'blue'} // Change border color on focus
//                                     onBlur={(e) => e.target.style.borderColor = 'black'} // Reset border color on blur
//                                 />


//                                 <button
//                                     onClick={handleSendMessage}
//                                     style={{ marginLeft: '10px', padding: '10px 15px', backgroundColor: 'rgb(125, 133, 195)', color: 'white', border: 'none', borderRadius: '20px' }}
//                                 >
//                                     Send
//                                     {newMessage.trim() && ( // Only show the button if there is text
//                                         <IoSend style={{ margin: '5px', }} />
//                                     )}
//                                 </button>

//                             </div>
                          
//                         </>
//                     ) : (
//                         <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.</div>
//                     )}
//                 </div>




//                 {/* <div style={{ width: '20%', backgroundColor: '#f4f4f4', padding: '10px' }}>
//                     <h4><MdArchive /> Archived</h4>
//                     {filteredArchivedContacts.map(contact => (
//                         <div
//                             key={contact.id}
//                             style={{ cursor: 'pointer', padding: '10px', margin: '5px', borderBottom: '1px solid #eee' }}
//                         >
//                             <Row>
//                                 <Col>{contact.mobile}</Col>
//                                 <Col className='text-end'>
//                                     <Dropdown>
//                                         <Dropdown.Menu>
//                                             <Dropdown.Item href="#" onClick={() => handleUnarchiveContact(contact.id)}>
//                                                 Unarchive
//                                             </Dropdown.Item>
//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </Col>
//                             </Row>
//                         </div>
//                     ))}
//                 </div> */}
//             </div>
//         </>

//     );
// };

// export default SendUserMessage;



import React, { useState, useEffect } from 'react';
import Sidebar from '../Chat/Sidebar';
import { IoIosSearch, IoMdContact } from 'react-icons/io';
import { IoSend } from 'react-icons/io5';
import bg from './bg.jpeg';
import { BsEmojiGrin, BsThreeDotsVertical } from 'react-icons/bs';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { MdOutlineSendAndArchive } from 'react-icons/md';
import EmojiPicker from 'emoji-picker-react'; // Import emoji picker component

const SendUserMessage = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [activeDropdown, setActiveDropdown] = useState(null); // Track active dropdown
    const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Show/hide emoji picker
    const [selectedEmoji, setSelectedEmoji] = useState(''); // Selected emoji for the message
    const [response, setResponse] = useState('');
    const [error, setError] = useState('');


    const [departUserMobile, setDepartUserMobile] = useState(''); // Store the mobile number from departuser

 
    useEffect(() => {
        const fetchUserData = async () => {
            const userIdss = localStorage.getItem('userId');
            const userIdInt = parseInt(userIdss, 10);
    
            if (userIdInt) {
                try {
                    const userResponse = await fetch(`https://demochatapi.emedha.in/api/api1/departuser?userId=${userIdInt}`);
                    const userData = await userResponse.json();
    
                    // Assuming the mobile number is in userData.departuser and mapping it
                    if (userData.departuser && userData.departuser.length > 0) {
                        const userMobile = userData.departuser[0].mobile; // Assuming you need the first user
                        setDepartUserMobile(userMobile); // Store the mobile number
                    }
                } catch (error) {
                    console.error('Error fetching departuser data:', error);
                }
            }
        };
    
        fetchUserData();
    }, []);
    
console.log(departUserMobile);


useEffect(() => {
    const fetchContactsWithUserId = async () => {
        const userId = localStorage.getItem('userId');
        const userIdInt = parseInt(userId, 10);

        if (userIdInt) {
            try {
                const response = await fetch(`https://demochatapi.emedha.in/api/api1/contacts?userId=${userIdInt}`, {
                    method: 'GET',
                });
                const data = await response.json();
                setContacts(data.contacts || []);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        }
    };

    fetchContactsWithUserId();
}, []);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const response = await fetch('https://demochatapi.emedha.in/api/contacts', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    const results = await response.json();
                    setContacts(results);
                }
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchContacts();
    }, []);

    const handleContactClick = (contact) => {
        setSelectedContact(contact);
        setMessages([]); // Reset messages for the selected contact
    };


    

    // const handleSendMessage = async () => {
    //     if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send

    //     const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji

    //     // Create a new message object to display in the chat
    //     const newMessageObject = {
    //         id: messages.length + 1,
    //         text: messageToSend,
    //         sender: 'me',
    //         timestamp: new Date().toLocaleTimeString(),
    //     };

    //     // Update the messages state
    //     setMessages((prevMessages) => [...prevMessages, newMessageObject]);

    //     try {
    //         const mobile = selectedContact.mobile; // Get the selected contact's mobile number
    //         const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=${mgrmobile}&message=${encodeURIComponent(messageToSend)}`, {
    //             method: 'POST', // Using POST as per the API specification
    //         });

    //         if (!res.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const contentType = res.headers.get("content-type");
    //         let data;

    //         if (contentType && contentType.includes("application/json")) {
    //             data = await res.json();
    //         } else {
    //             data = await res.text();
    //         }

    //         setResponse(data); // Handle the response from the API
    //     } catch (err) {
    //         setError(err.message);
    //     } finally {
    //         // Clear the input field and reset emoji
    //         setNewMessage('');
    //         setSelectedEmoji('');
    //     }
    // };


    const handleSendMessage = async () => {
        if (newMessage.trim() === '' && !selectedEmoji) return; // If no message or emoji, don't send
    
        const messageToSend = newMessage + selectedEmoji; // Combine text message and emoji
    
        // Create a new message object to display in the chat
        const newMessageObject = {
            id: messages.length + 1,
            text: messageToSend,
            sender: 'me',
            timestamp: new Date().toLocaleTimeString(),
        };
    
        // Update the messages state
        setMessages((prevMessages) => [...prevMessages, newMessageObject]);
    
        try {
            const mobile = selectedContact.mobile; // Get the selected contact's mobile number
            const mgrmobile = departUserMobile; // Use the mobile number from departuser
    
            if (!mgrmobile) {
                throw new Error("Manager's mobile number is not available.");
            }
    
            const res = await fetch(`https://emedha.com/chatapp/sendusermsg.php?mobile=${mobile}&srcmobile=${mgrmobile}&message=${encodeURIComponent(messageToSend)}`, {
                method: 'POST', // Using POST as per the API specification
            });
    
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
    
            const contentType = res.headers.get("content-type");
            let data;
    
            if (contentType && contentType.includes("application/json")) {
                data = await res.json();
            } else {
                data = await res.text();
            }
    
            setResponse(data); // Handle the response from the API
        } catch (err) {
            setError(err.message);
        } finally {
            // Clear the input field and reset emoji
            setNewMessage('');
            setSelectedEmoji('');
        }
    };
    

    const handleEmojiClick = (emojiObject) => {
        // Append the selected emoji to the current message
        setNewMessage(prevMessage => prevMessage + emojiObject.emoji);
         setShowEmojiPicker(false); // Close the emoji picker after selection
    };
    
    const filteredContacts = contacts.filter(contact =>
        contact.mobile.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleDropdown = (contactId) => {
        if (activeDropdown === contactId) {
            setActiveDropdown(null); // If the same contact is clicked again, hide the dropdown
        } else {
            setActiveDropdown(contactId); // Show the dropdown for the clicked contact
        }
    };

    return (
        <>
            <Sidebar />
            <div id='main' style={{ display: 'flex', height: '100vh', fontFamily: 'Arial, sans-serif' }}>
                <div style={{ backgroundColor: '#E6E8F4', width: '20%', borderRight: '1px solid #ccc', padding: '10px' }}>
                    <Row>
                        <Col>
                            <h3 style={{ margin: '0 0 10px' }}>Contacts</h3>
                        </Col>
                        <Col className='text-end'>
                            <BsThreeDotsVertical style={{ fontSize: '20px' }} />
                        </Col>
                    </Row>

                    <div style={{ marginBottom: '10px', position: 'relative', width: '230px', display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search"
                            className="form-control"
                            style={{
                                paddingLeft: '40px',
                                paddingRight: '40px',
                                borderRadius: '25px',
                                border: '1px solid rgb(125, 133, 195)',
                                transition: 'border-color 0.3s',
                            }}
                            onFocus={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                            onBlur={(e) => e.target.style.borderColor = 'rgb(125, 133, 195)'}
                        />
                        <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#ced4da' }} size={20} />
                    </div>
<div  className="dropdowns-scrollable">
                    {filteredContacts.map(contact => (
                        <div
                            key={contact.id}
                            onClick={() => handleContactClick(contact)}
                            style={{
                                cursor: 'pointer',
                                padding: '10px',
                                margin: '5px',
                                borderBottom: '1px solid #eee',
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = '#7D86C3';
                                e.currentTarget.style.color = 'white';
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = '#E6E8F4';
                                e.currentTarget.style.color = 'black';
                            }}
                        >
                            <Row>
                                <Col>{contact.mobile}</Col>
                                <Col className='text-end'>
                                    <MdOutlineSendAndArchive
                                        id="dropdown-custom-components"
                                        style={{ fontSize: '20px', cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent dropdown from closing
                                            toggleDropdown(contact.id); // Toggle dropdown
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ))}
</div>
                </div>

                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    {selectedContact ? (
                        <>
                            <div style={{ borderBottom: '1px solid #ccc' }}>
                                <h3 className='container-fluid bg-light p-3 shadow' style={{ margin: 0, fontSize: '20px' }}>
                                    <IoMdContact style={{ fontSize: '30px', color: 'rgb(125, 133, 195)', margin: '5px' }} />
                                    {selectedContact.mobile}
                                </h3>
                            </div>

                            <div style={{
                                flex: 1,
                                overflowY: 'scroll',
                                padding: '10px',
                                border: '1px solid #ccc',
                                backgroundColor: '#E6E8F4',
                                backgroundImage: `linear-gradient(to top, rgba(200, 203, 230, 0.325), rgba(125, 133, 195, 0.335)), url(${bg})`,
                                borderTop: 'none'
                            }}>
                                {messages.map(msg => (
                                    <div key={msg.id} style={{ margin: '5px 0', textAlign: msg.sender === 'me' ? 'right' : 'left' }}>
                                        <div style={{
                                            display: 'inline-block',
                                            padding: '8px',
                                            borderRadius: '10px',
                                            backgroundColor: msg.sender === 'me' ? '#dcf8c6' : '#ffffff',
                                            maxWidth: '70%',
                                            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                        }}>
                                            <strong>{msg.sender === 'me' ? 'You' : selectedContact.mobile}:</strong>
                                            <span> {msg.text} </span>
                                            <small style={{ marginLeft: '10px', color: 'gray' }}>{msg.timestamp}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div style={{ display: 'flex', padding: '10px', backgroundColor: '#E6E8F4', borderTop: '1px solid #ccc' }}>
                            <div style={{ position: 'relative' }}>
    <button
        style={{
            padding: '5px',
            backgroundColor: '#E6E8F4',
            border: '1px solid #ccc',
            borderRadius: '50%',
            cursor: 'pointer'
        }}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
    >
         <BsEmojiGrin style={{color:'black',fontSize:'28px'}}/>
    </button>

    {/* Emoji picker will now appear above the button */}
    {showEmojiPicker && (
        <div style={{
            position: 'absolute',
            bottom: '40px', // Adjust this value depending on your layout to move the picker above the button
            left: '0',
            zIndex: '1000', // Ensure the emoji picker is on top of other elements
        }}>
            <EmojiPicker onEmojiClick={handleEmojiClick} />
        </div>
    )}
</div>

                                <input
                                    type="text"
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    placeholder="Type a message...."
                                    style={{
                                        flex: 1,
                                        padding: '10px',
                                        paddingLeft: '40px',
                                        paddingRight: '40px',
                                        borderRadius: '25px',
                                        border: '1px solid black',
                                    }}
                                    onFocus={(e) => e.target.style.borderColor = 'blue'}
                                    onBlur={(e) => e.target.style.borderColor = 'black'}
                                />

                                <button
                                    onClick={handleSendMessage}
                                    style={{
                                        marginLeft: '10px',
                                        padding: '10px 15px',
                                        backgroundColor: 'rgb(125, 133, 195)',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '20px'
                                    }}
                                >
                                    Send
                                    {newMessage.trim() && ( // Only show the button if there is text
                                        <IoSend style={{ margin: '5px' }} />
                                    )}
                                </button>
                            </div>
                        </>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>Please select a contact to start chatting.</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SendUserMessage;





