import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ChatPanel from './Components/Chat/Chatpanel';
import Login from './Components/Auth/Login';
import Signup from './Components/Auth/Signup';
import ForgotPassword from './Components/Auth/ForgotPassword';
import OtpVerificationForm from './Components/Auth/OtpForm/Otp';
import PasswordResetForm from './Components/Auth/PasswordResetFrom/PasswordResetFrom';
import ProtectedRoute from './Components/Auth/ProtectedRoute/ProtectedRoute';
import AddAdmin from './Components/AddAdmin/AddAdmin';
import Sidebar from './Components/Chat/Sidebar';
import Dashboard from './Components/Dashbord/Dashbord';
import SuperAdminDashboard from './Components/Dashbord/SuperAdminDashboard';
import Chat from './Components/Chat/Chat';
import GroupChat from './Components/Chat/GroupChat';
import AdminLogin from './Components/Auth/AdminLogin';
import ManageContact from './Components/Manage Contacts/Manage Contacts';
import ManageGroups from './Components/Manage Groups/ManageGroups';
import AddminForgotPassword from './Components/AddAdmin/AdminForgotPassword';
import Credits from './Components/Credits/Credits';
import Transaction from './Components/Transaction.js/Transaction';
import Create from './Components/Template/Create';
import View from './Components/Template/View';
import Message from './Components/Message/Message';
import TransactionMessage from './Components/TransactionMessage/TransactionMessage';
import SendMessage from './Components/Message/SendMessage';
import SendUserMessage from './Components/Message/SendUserMessage';

function App() {
  return (
    
    <>
   
    <Router>
      <Routes>
    
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/otp-form" element={<OtpVerificationForm />} />
          <Route path="/reset-password" element={<PasswordResetForm />} />
          <Route path="/reset-password/:token" element={<PasswordResetForm />} />
          <Route path='/dashbord' element={<ProtectedRoute element={<Dashboard/>}/>}/>
          <Route path='/dashboard' element={<ProtectedRoute element={<SuperAdminDashboard/>}/>}/>
          <Route path='/addAdmin' element={<ProtectedRoute element={<AddAdmin />} />}/>
          <Route path='/sidebar'  element={<ProtectedRoute element={<Sidebar />} />}/> 
          <Route path="/chat/:contactId" element={<Chat />} />
          <Route path='/adminlogin'  element={<AdminLogin/>}/>
          {/* <Route path="/" element={<GroupList groups={groups} />} /> */}
                <Route path="/groupchat/:groupId" element={<GroupChat />} />
        {/* <Route path="/chatpanel" element={<ProtectedRoute element={<ChatPanel/>}/> }/> */}
        <Route path='/manageContacts' element={<ProtectedRoute element={<ManageContact/>}/> }/>
        <Route path='/manageGroups'  element={<ProtectedRoute  element={<ManageGroups/>}/> }/>
        <Route path='/adminforgotPassword'  element={<ProtectedRoute  element={<AddminForgotPassword/>}/> }/>
        <Route path='/credits'  element={<ProtectedRoute  element={<Credits/>}/> }/>
        <Route path='/transaction'  element={<ProtectedRoute  element={<Transaction/>}/> }/>
        <Route path='/create'  element={<ProtectedRoute  element={<Create/>}/> }/>
        <Route path='/view'  element={<ProtectedRoute  element={<View/>}/> }/>
        <Route path='/message'  element={<ProtectedRoute  element={<Message/>}/> }/>
        <Route path='/transactionMessage'  element={<ProtectedRoute  element={<TransactionMessage/>}/> }/>
        <Route path='/sendMessage'  element={<ProtectedRoute  element={<SendMessage/>}/> }/>
        <Route path='/sendUserMessage'  element={<ProtectedRoute  element={<SendUserMessage/>}/> }/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
