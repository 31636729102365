// import React, { useEffect, useState } from "react";
// import { Pagination, Table } from "react-bootstrap";
// import Sidebar from "../Chat/Sidebar";

// const Transaction = () => {
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [filteredTransactions, setFilteredTransactions] = useState([]);
//     const [searchInput, setSearchInput] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 5; // Number of transactions per page

//     useEffect(() => {

//            const fetchTransactions = async () => {
//           try {
//             const response = await fetch('https://demochatapi.emedha.in/api/transactions');
//             const data = await response.json();
//             const sortedData = data.sort((a, b) => a.id - b.id);
//             setTransactionHistory(sortedData);
//             setFilteredTransactions(sortedData); // Initialize filtered transactions
//           } catch (error) {
//             console.error('Error fetching transactions:', error);
//           }
//         };


//         fetchTransactions();
//       }, []);




//   // Search filter
//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearchInput(value);

//     const filtered = transactionHistory.filter(transaction =>
//       transaction.username.toLowerCase().includes(value.toLowerCase())
//     );
//     setFilteredTransactions(filtered);
//     setCurrentPage(1); // Reset to first page on search
//   };

//   // Pagination
//   const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
//   const currentTransactions = filteredTransactions.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };
//     return (
//         <>
//         <Sidebar/>
//             <div id='main'>

//             <h3>Transaction History</h3>
//           <input
//             type="text"
//             value={searchInput}
//             onChange={handleSearch}
//             placeholder="Search by username"
//           />
//           <div className="transaction-history">
//             {currentTransactions.length === 0 ? (
//               <p>No transactions found.</p>
//             ) : (
//               <Table>
//                 <thead>
//                   <tr>
//                     <th>ID</th>
//                     <th>Username</th>
//                     <th>Credits</th>
//                     <th>Transactions Time/Date </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentTransactions.map((transaction) => (
//                     <tr key={transaction.id}>
//                       <td>{transaction.id}</td>
//                       <td>{transaction.username}</td>
//                       <td>{transaction.credits}</td>
//                       <td>{new Date(transaction.createdAt).toLocaleString()}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             )}
//           </div>

//           {/* Pagination Controls */}
//           <Pagination className="text-end , position-fixed bottom-0 start-20 p-5  mt-3 ">
//             {Array.from({ length: totalPages }, (_, index) => (
//               <Pagination.Item
//                 key={index + 1}
//                 active={index + 1 === currentPage}
//                 onClick={() => handlePageChange(index + 1)}
//               >
//                 {index + 1}
//               </Pagination.Item>
//             ))}
//           </Pagination>



//             </div>
//         </>
//     );
// };

// export default Transaction;

// import React, { useEffect, useState } from "react";
// import { Pagination, Table } from "react-bootstrap";
// import Sidebar from "../Chat/Sidebar";

// const Transaction = () => {
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [filteredTransactions, setFilteredTransactions] = useState([]);
//     const [searchInput, setSearchInput] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 4; // Number of transactions per page

//     useEffect(() => {
//         const fetchTransactions = async () => {
//             try {
//                 const response = await fetch('https://demochatapi.emedha.in/api/transactions');
//                 const data = await response.json();
//                 const sortedData = data.sort((a, b) => a.id - b.id);
//                 setTransactionHistory(sortedData);
//                 setFilteredTransactions(sortedData); // Initialize filtered transactions
//             } catch (error) {
//                 console.error('Error fetching transactions:', error);
//             }
//         };
//         fetchTransactions();
//     }, []);

//     // Search filter
//     const handleSearch = (e) => {
//         const value = e.target.value;
//         setSearchInput(value);

//         const filtered = transactionHistory.filter(transaction => {
//             const usernameMatch = transaction.username.toLowerCase().includes(value.toLowerCase());
//             const creditsMatch = transaction.credits.toString().includes(value);
//             const dateMatch = new Date(transaction.createdAt).toLocaleString().toLowerCase().includes(value.toLowerCase());

//             return usernameMatch || creditsMatch || dateMatch;
//         });
//         setFilteredTransactions(filtered);
//         setCurrentPage(1); // Reset to first page on search
//     };

//     // Pagination logic
//     const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
//     const currentTransactions = filteredTransactions.slice(
//         (currentPage - 1) * itemsPerPage,
//         currentPage * itemsPerPage
//     );

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     const renderPaginationItems = () => {
//         const items = [];
//         // Previous button
//         items.push(
//             <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//                 &lt;
//             </Pagination.Item>
//         );

//         // Page numbers and ellipses
//         for (let i = 1; i <= totalPages; i++) {
//             if (i > 1 && i < totalPages && (i < currentPage - 1 || i > currentPage + 1)) {
//                 if (items[items.length - 1]?.props.children !== "...") {
//                     items.push(<Pagination.Ellipsis key={`ellipsis-${i}`} />);
//                 }
//                 continue;
//             }
//             items.push(
//                 <Pagination.Item
//                     key={i}
//                     active={i === currentPage}
//                     onClick={() => handlePageChange(i)}
//                 >
//                     {i}
//                 </Pagination.Item>
//             );
//         }

//         // Next button
//         items.push(
//             <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//                 &gt;
//             </Pagination.Item>
//         );

//         return items;
//     };

//     return (
//         <>
//             <Sidebar />
//             <div id='main'>
//                 <h3>Transaction History</h3>
//                 <input
//                     type="text"
//                     value={searchInput}
//                     onChange={handleSearch}
//                     placeholder="Search "
//                 />
//                 <div className="transaction-history">
//                     {currentTransactions.length === 0 ? (
//                         <p>No transactions found.</p>
//                     ) : (
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th>ID</th>
//                                     <th>Username</th>
//                                     <th>Credits</th>
//                                     <th>Transaction Time/Date</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {currentTransactions.map(transaction => (
//                                     <tr key={transaction.id}>
//                                         <td>{transaction.id}</td>
//                                         <td>{transaction.username}</td>
//                                         <td>{transaction.credits}</td>
//                                         <td>{new Date(transaction.createdAt).toLocaleString()}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table>
//                     )}
//                 </div>

//                 {/* Pagination Controls */}
//                 <Pagination className="text-end position-fixed bottom-0 start-20 p-5 mt-3">
//                     {renderPaginationItems()}
//                 </Pagination>
//             </div>
//         </>
//     );
// };

// export default Transaction;


// import React, { useEffect, useState } from "react";
// import { Pagination, Table, Dropdown, Row, Col } from "react-bootstrap";
// import Sidebar from "../Chat/Sidebar";
// import { IoIosNotifications, IoIosSearch } from "react-icons/io";
// import { MdAccountCircle } from "react-icons/md";
// import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable';



// const Transaction = () => {
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [filteredTransactions, setFilteredTransactions] = useState([]);
//     const [searchInput, setSearchInput] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const [selectedRange, setSelectedRange] = useState('');
//     const itemsPerPage = 10; // Number of transactions per page
//     const navigate = useNavigate()

    // useEffect(() => {
    //     const fetchTransactions = async () => {
    //         try {
    //             const response = await fetch('https://demochatapi.emedha.in/api/transactions');
    //             const data = await response.json();
    //             const sortedData = data.sort((a, b) => a.id - b.id);
    //             setTransactionHistory(sortedData);
    //             setFilteredTransactions(sortedData); // Initialize filtered transactions
    //         } catch (error) {
    //             console.error('Error fetching transactions:', error);
    //         }
    //     };
    //     fetchTransactions();
    // }, []);



    

//     // Search filter
//     const handleSearch = (e) => {
//         const value = e.target.value;
//         setSearchInput(value);
//         filterTransactions(value, selectedRange);
//     };

//     const handleRangeSelect = (range) => {
//         setSelectedRange(range);
//         filterTransactions(searchInput, range);
//     };

//     // const filterTransactions = (searchValue, range) => {
//     //     const filtered = transactionHistory.filter(transaction => {
//     //         const usernameMatch = transaction.username.toLowerCase().includes(searchValue.toLowerCase());
//     //         const creditsMatch = transaction.credits.toString().includes(searchValue);

//     //         // Handle credit range filtering
//     //         let rangeMatch = true;
//     //         if (range) {
//     //             const [min, max] = range.split('-').map(Number);
//     //             rangeMatch = transaction.credits >= min && transaction.credits <= max;
//     //         }

//     //         return (usernameMatch || creditsMatch) && rangeMatch;
//     //     });
//     //     setFilteredTransactions(filtered);
//     //     setCurrentPage(1); // Reset to first page on filter
//     // };


//     const filterTransactions = (searchValue, range) => {
//         const filtered = transactionHistory.filter(transaction => {
//             const usernameMatch = transaction.username.toLowerCase().includes(searchValue.toLowerCase());
//             const creditsMatch = transaction.credits.toString().includes(searchValue);
//             return usernameMatch || creditsMatch;
//         });
//         setFilteredTransactions(filtered);
//         setCurrentPage(1); // Reset to first page on filter
//     };


//     // Pagination logic
//     const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
//     const currentTransactions = filteredTransactions.slice(
//         (currentPage - 1) * itemsPerPage,
//         currentPage * itemsPerPage
//     );

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     // const renderPaginationItems = () => {
//     //     const items = [];
//     //     // Previous button
//     //     items.push(
//     //         <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//     //             &lt;
//     //         </Pagination.Item>
//     //     );

//     //     // Page numbers and ellipses
//     //     for (let i = 1; i <= totalPages; i++) {
//     //         if (i > 1 && i < totalPages && (i < currentPage - 1 || i > currentPage + 1)) {
//     //             if (items[items.length - 1]?.props.children !== "...") {
//     //                 items.push(<Pagination.Ellipsis key={`ellipsis-${i}`} />);
//     //             }
//     //             continue;
//     //         }
//     //         items.push(
//     //             <Pagination.Item
//     //                 key={i}
//     //                 active={i === currentPage}
//     //                 onClick={() => handlePageChange(i)}
//     //             >
//     //                 {i}
//     //             </Pagination.Item>
//     //         );
//     //     }

//     //     // Next button
//     //     items.push(
//     //         <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//     //             &gt;
//     //         </Pagination.Item>
//     //     );

//     //     return items;
//     // };


//     const renderPaginationItems = () => {
//         const items = [];
//         items.push(
//             <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
//                 &lt;
//             </Pagination.Item>
//         );

//         for (let i = 1; i <= totalPages; i++) {
//             items.push(
//                 <Pagination.Item
//                     key={i}
//                     active={i === currentPage}
//                     onClick={() => handlePageChange(i)}
//                 >
//                     {i}
//                 </Pagination.Item>
//             );
//         }

//         items.push(
//             <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
//                 &gt;
//             </Pagination.Item>
//         );

//         return items;
//     };

//     const [dropdownVisible, setDropdownVisible] = useState(false);

//     const toggleDropdown = () => {
//       setDropdownVisible(!dropdownVisible);
//     };
    
//     const handleItemClick = (action) => {
//       console.log(action);
//       setDropdownVisible(false);
//     };
    
//     const handleLogout = () => {
//       localStorage.removeItem('userData');
//       localStorage.removeItem('token');
//       localStorage.removeItem('loginEmail');
//       localStorage.removeItem('rememberMe');
//       sessionStorage.removeItem('username');
//       localStorage.removeItem('userId');
//       navigate('/');
//     };
    


//     const downloadPDF = (transactions) => {
//         // Check if there are any transactions
//         if (transactions.length === 0) {
//             alert('No transactions found.');
//             return;
//         }
    
//         const doc = new jsPDF();
        
//         // Prepare the data for the PDF
//         const tableData = transactions.map(transaction => ({
//             ID: transaction.id,
//             Username: transaction.username,
//             Credits: transaction.credits,
//             TransactionDate: new Date(transaction.createdAt).toLocaleString(),
//         }));
    
//         // Create the table in the PDF
//         autoTable(doc, {
//             head: [['ID', 'Username', 'Credits', 'Transaction Time/Date']],
//             body: tableData.map(item => [item.ID, item.Username, item.Credits, item.TransactionDate]),
//             startY: 20, // Starting position on the Y axis
//         });
    
//         // Save the generated PDF
//         doc.save('transactions.pdf');
//     };

//     const startingIndex = (currentPage - 1) * itemsPerPage;

//     return (
//         <>
//             <Sidebar />
//             <div id='main'>

//             <div className="container-fluid bg-light p-3 shadow">
//           <div className="row justify-content-between align-items-center">
//             <div className="col-auto">
//               <h3 className="mt-2"><strong>Transaction History</strong></h3>
//             </div>
//             <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
//               <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
//               <div style={{ position: 'relative' }}>
//                 <MdAccountCircle
//                   size={30}
//                   onClick={toggleDropdown}
//                   style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
//                 />
//                 {dropdownVisible && (
//                   <div className="dropdown-menu show" style={{
//                     position: 'absolute',
//                     right: 0,
//                     backgroundColor: 'white',
//                     border: '1px solid #ccc',
//                     boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//                     zIndex: 1,
//                   }}>
//                     <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
//                       <FaUserEdit style={{ marginRight: '8px' }} />
//                       Edit Profile
//                     </div>
//                     <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
//                       <FaLock style={{ marginRight: '8px' }} />
//                       Change Password
//                     </div>
//                     <div onClick={handleLogout} className="dropdown-item">
//                       <FaSignOutAlt style={{ marginRight: '8px' }} />
//                       Logout
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>




//               <hr />
//                 {/* <input
//                     type="text"
//                     value={searchInput}
//                     onChange={handleSearch}
//                     placeholder="Search by username"
//                 /> */}
// <Row>
// <Col>
// <div  style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
//   <input
//     type="text"
//     value={searchInput}
//                         onChange={handleSearch}
//                         placeholder="Search "
//  className="form-control"
//     style={{
//       paddingLeft: '40px', // Space for the icon
//       paddingRight: '40px', // Space for any right-side elements
//       borderRadius: '25px', // Rounded corners
//       border: '1px solid #ced4da', // Border color
    
//       transition: 'border-color 0.3s',
//     }}
//     onFocus={(e) => e.target.style.borderColor = '#007bff'} // Change border color on focus
//     onBlur={(e) => e.target.style.borderColor = '#ced4da'} // Reset border color on blur
//   />
//   <IoIosSearch
   
//     style={{ position: 'absolute', left: '10px', color: '#6c757d' }}
//     size={20} // Adjust size as needed
//   />
 
// </div>
// </Col>
// <Col>
// <div className='d-flex flex-row justify-content-end '>
//      <button style={{ backgroundColor: 'rgb(125, 133, 195)' }} onClick={() => downloadPDF(currentTransactions)}>
//                 Download PDF
//             </button>
//             </div>
//             </Col>
//             </Row>



//                 {/* Dropdown for Credit Ranges */}
//                 {/* <Dropdown className="mt-3" >
//                     <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: 'rgb(125, 133, 195)' }}>
//                         {selectedRange ? `Credits From: ${selectedRange}` : 'Select Credit Range'}
//                     </Dropdown.Toggle>

//                     <Dropdown.Menu>
//                         <Dropdown.Item onClick={() => handleRangeSelect('10-30')}>10-30</Dropdown.Item>
//                         <Dropdown.Item onClick={() => handleRangeSelect('30-60')}>30-60</Dropdown.Item>
//                         <Dropdown.Item onClick={() => handleRangeSelect('60-100')}>60-100</Dropdown.Item>
//                         <Dropdown.Item onClick={() => handleRangeSelect('')}>Clear Range</Dropdown.Item>
//                     </Dropdown.Menu>
//                 </Dropdown> */}

//                 <div style={{marginTop:'20px',  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>
//                     {currentTransactions.length === 0 ? (
//                         <p>No transactions found.</p>
//                     ) : (
//                         <Table>
//                             <thead>
//                                 <tr>
//                                     <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>ID</th>
//                                     <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Username</th>
//                                     <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Credits</th>
//                                     <th style={{  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>Transaction Time/Date</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {currentTransactions.map((transaction ,index) => (
//                                     <tr key={transaction.id}>
//                                       <td>{startingIndex + index + 1}</td> {/* Updated row index */}
//                                         <td>{transaction.username}</td>
//                                         <td>{transaction.credits}</td>
//                                         <td>{new Date(transaction.createdAt).toLocaleString()}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </Table>
                        
//                     )}
//                 </div>
               

//                 {/* Pagination Controls */}
//                 <Pagination className='d-flex flex-row justify-content-end '>
//                     {renderPaginationItems()}
//                 </Pagination>
//             </div>
//         </>
//     );
// };

// export default Transaction;


import React, { useEffect, useState } from "react";
import { Pagination, Table, Dropdown, Row, Col } from "react-bootstrap";
import Sidebar from "../Chat/Sidebar";
import { IoIosNotifications, IoIosSearch } from "react-icons/io";
import { MdAccountCircle } from "react-icons/md";
import { FaLock, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const Transaction = () => {
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRange, setSelectedRange] = useState('');
    const itemsPerPage = 10; // Number of transactions per page
    const navigate = useNavigate()

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                  const token = localStorage.getItem('token'); // Assuming the token is stored under this key
                if (token) {
                const response = await fetch('https://demochatapi.emedha.in/api/transactions',{
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the token in the headers if needed
                    },
                });
                const data = await response.json();
                const sortedData = data.sort((a, b) => a.id - b.id);
                setTransactionHistory(sortedData);
                setFilteredTransactions(sortedData); // Initialize filtered transactions
            } 
        }  catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
        fetchTransactions();
    }, []);
    

    useEffect(() => {
        const fetchTransactions = async () => {
            const userIdss = localStorage.getItem('userId')
            const userIdInt = parseInt(userIdss, 10);

            
            if (userIdInt) {
           
           

            try {
                // Replace with your actual API URL
                const response = await fetch(`https://demochatapi.emedha.in/api/api1/transactions?userId=${userIdInt}`);
                const data = await response.json();

                const sortedData = data.transactions.sort((a, b) => a.id - b.id);
                setTransactionHistory(sortedData);
                setFilteredTransactions(sortedData); // Initialize filtered transactions
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        }
        };

        fetchTransactions();
    }, []);

    // Search filter
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        filterTransactions(value, selectedRange);
    };

    const handleRangeSelect = (range) => {
        setSelectedRange(range);
        filterTransactions(searchInput, range);
    };

    const filterTransactions = (searchValue, range) => {
        const filtered = transactionHistory.filter(transaction => {
            const usernameMatch = transaction.username.toLowerCase().includes(searchValue.toLowerCase());
            const creditsMatch = transaction.credits.toString().includes(searchValue);
            return usernameMatch || creditsMatch;
        });
        setFilteredTransactions(filtered);
        setCurrentPage(1); // Reset to first page on filter
    };

    // Pagination logic
    const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);
    const currentTransactions = filteredTransactions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPaginationItems = () => {
        const items = [];
        items.push(
            <Pagination.Item key="prev" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                &lt;
            </Pagination.Item>
        );

        for (let i = 1; i <= totalPages; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Item key="next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                &gt;
            </Pagination.Item>
        );

        return items;
    };

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
    
    const handleItemClick = (action) => {
      console.log(action);
      setDropdownVisible(false);
    };
    
    const handleLogout = () => {
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('rememberMe');
      sessionStorage.removeItem('username');
      localStorage.removeItem('userId');
      navigate('/');
    };

    const downloadPDF = (transactions) => {
        if (transactions.length === 0) {
            alert('No transactions found.');
            return;
        }
    
        const doc = new jsPDF();
        const tableData = transactions.map(transaction => ({
            ID: transaction.id,
            Username: transaction.username,
            Credits: transaction.credits,
            TransactionDate: new Date(transaction.createdAt).toLocaleString(),
        }));
    
        autoTable(doc, {
            head: [['ID', 'Username', 'Credits', 'Transaction Time/Date']],
            body: tableData.map(item => [item.ID, item.Username, item.Credits, item.TransactionDate]),
            startY: 20,
        });
    
        doc.save('transactions.pdf');
    };

    const startingIndex = (currentPage - 1) * itemsPerPage;

    return (
        <>
            <Sidebar />
            <div id='main'>
                <div className="container-fluid bg-light p-3 shadow">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h3 className="mt-2"><strong>Transaction History</strong></h3>
                        </div>
                        <div className="col-auto d-flex align-items-center" style={{ gap: '20px' }}>
                            <IoIosNotifications size={30} style={{ color: 'rgb(125, 133, 195)' }} />
                            <div style={{ position: 'relative' }}>
                                <MdAccountCircle
                                    size={30}
                                    onClick={toggleDropdown}
                                    style={{ cursor: 'pointer', color: 'rgb(125, 133, 195)' }}
                                />
                                {dropdownVisible && (
                                    <div className="dropdown-menu show" style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}>
                                        <div onClick={() => handleItemClick('Edit Profile')} className="dropdown-item">
                                            <FaUserEdit style={{ marginRight: '8px' }} />
                                            Edit Profile
                                        </div>
                                        <div onClick={() => handleItemClick('Change Password')} className="dropdown-item">
                                            <FaLock style={{ marginRight: '8px' }} />
                                            Change Password
                                        </div>
                                        <div onClick={handleLogout} className="dropdown-item">
                                            <FaSignOutAlt style={{ marginRight: '8px' }} />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />
                <Row>
                    <Col>
                        <div style={{ position: 'relative', width: '300px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                value={searchInput}
                                onChange={handleSearch}
                                placeholder="Search"
                                className="form-control"
                                style={{
                                    paddingLeft: '40px', 
                                    paddingRight: '40px', 
                                    borderRadius: '25px', 
                                    border: '1px solid #ced4da',
                                    transition: 'border-color 0.3s',
                                }}
                                onFocus={(e) => e.target.style.borderColor = '#007bff'}
                                onBlur={(e) => e.target.style.borderColor = '#ced4da'}
                            />
                            <IoIosSearch style={{ position: 'absolute', left: '10px', color: '#6c757d' }} size={20} />
                        </div>
                    </Col>
                    <Col>
                        <div className='d-flex flex-row justify-content-end'>
                            <button style={{ backgroundColor: 'rgb(125, 133, 195)' }} onClick={() => downloadPDF(currentTransactions)}>
                                Download PDF
                            </button>
                        </div>
                    </Col>
                </Row>

                <div style={{marginTop:'20px',  backgroundColor: 'rgb(125, 133, 195)', color: 'white'}}>
                    {currentTransactions.length === 0 ? (
                        <p>No transactions found.</p>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>ID</th>
                                    <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Username</th>
                                    <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Credits</th>
                                    <th style={{ backgroundColor: 'rgb(125, 133, 195)', color: 'white' }}>Transaction Time/Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTransactions.map((transaction, index) => (
                                    <tr key={transaction.id}>
                                        <td>{startingIndex + index + 1}</td>
                                        <td>{transaction.username}</td>
                                        <td>{transaction.credits}</td>
                                        <td>{new Date(transaction.createdAt).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>

                {/* Pagination Controls */}
                <Pagination className='d-flex flex-row justify-content-end'>
                    {renderPaginationItems()}
                </Pagination>
            </div>
        </>
    );
};

export default Transaction;
